import Vue from 'vue'
import App from './App.vue'
import router from './router'
import http from 'axios'//引入axios
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
console.log(window.location.host+'/trace')
const axios=http.create({
  // baseURL:process.env.VUE_APP_BASE_URL
  baseURL:'/trace'
  // baseURL:"http://192.168.3.148:8000"
})

Vue.prototype.$axios = axios;//把axios挂载到vue上

Vue.use(VideoPlayer)
Vue.use(ElementUI)
Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
